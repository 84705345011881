import axios, {AxiosResponse} from "axios";
import {config} from "../config";
import GeneralService from "./GeneralService";


export default class UserService {


    static async removeCard(cardId: number) {

        return axios.post(config.serverUrl + '/api/editor/remove', {card_id: cardId}, GeneralService.makeHeader())
    }

    static async addCard(cardId: number) {

        return axios.post(config.serverUrl + '/api/editor/add-card', {card_id: cardId}, GeneralService.makeHeader())
    }

    static async editUser(username: string, password: string) {

        // @ts-ignore
        return axios.put(config.serverUrl + '/api/player/edit', {
                login: username,
                password: password
            }, GeneralService.makeHeader()
        )
    }

    static async openBox(boxId: number) {

        // @ts-ignore
        return axios.post(config.serverUrl + '/api/boxes/open', {
                id: boxId
            }, GeneralService.makeHeader()
        )
    }

    static async getBoxes() {

        // @ts-ignore
        return axios.get(config.serverUrl + '/api/boxes', GeneralService.makeHeader()
        )
    }

    static async nutakuPaymentImplement(payment_id:number) {

        // @ts-ignore
        return axios.post(config.serverUrl + '/api/nutaku/payment/implement',{payment_id: payment_id}, GeneralService.makeHeader())
    }

    static async nutakuPaymentCreate(id:number) {

        // @ts-ignore
        return axios.post(config.serverUrl + '/api/nutaku/payment/create',{id: id}, GeneralService.makeHeader()
        )
    }

    static async getNutakuBoxes() {

        // @ts-ignore
        return axios.get(config.serverUrl + '/api/nutaku/payment/boxes', GeneralService.makeHeader()
        )
    }

    static async sendEvent(event: string) {

        // @ts-ignore
        return axios.post(config.serverUrl + '/api/player/events', {
                event: event,
            }, GeneralService.makeHeader()
        )
    }

    static async authUser(nutakuId: string, nickname: string) {

        return axios.post(config.serverUrl + '/api/auth', {
            nutaku_id: nutakuId,
            nickname: nickname
        })
    }

    static async updateUser() {

        // @ts-ignore
        let response = {'data': false};

        // @ts-ignore
        response = axios.get(config.serverUrl + '/api/player-details', GeneralService.makeHeader()).catch(function (error) {
            return {'data': false};
        });

        return response;
    }

    static async login(username: string, password: string) {
        return axios.post(config.serverUrl + '/api/login', {
            login: username,
            password: password
        })
    }

    static async playNewPlayers() {
        return axios.post(config.serverUrl + '/api/play-new-player')
    }

    static async getFiles() {

        // @ts-ignore
        return axios.get(config.serverUrl + '/api/files')
    }
}
