import './Shop.css';
import {NutakuPaymentService} from "../../../services/NutakuPaymentService";
import {useEffect} from "react";
import UserService from "../../../api/UserService";
import {useActions} from "../../../hooks/useActions";

function NutakuBox() {
    const {updateUser} = useActions();
    useEffect(() => {
        const onMessage = async (event: any) => {

            if (event.data.type === "payment_callback") {
                alert(event.data.order.responseCode);
                if (event.data.order.responseCode == 'ok') {
                    await UserService.nutakuPaymentImplement(event.data.order.paymentId);
                    updateUser();
                }
            }
        };

        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        }
    }, []);

    return (
        <div onClick={() => {

            NutakuPaymentService.start(1);
        }}>
            Buy for NUTAKU gold 100
        </div>
    );
};


export default NutakuBox;