import UserService from "../api/UserService";

export const NutakuPaymentService = {

    start: async function (id: number) {


        let result = await UserService.nutakuPaymentCreate(id);

        alert('send');
        let message = {
            type: 'payment',
            order: {
                order_id: result.data.id,
                id: id,
                price: 100,
                count: 1,
                description: '1000 gems',
                name: '1000 gems',
                image_url: 'https://example.com/gun.gif'
            }
        };


        window.parent.postMessage(message, '*');
    }
}